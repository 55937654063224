import React from 'react';
import Layout from '../components/layout';
import TextBlock from "../components/TextBlock/textBlock"

const NotFoundPage = () => (
  <Layout>
      <TextBlock
        id="404"
        title="Oops.."
        paragraph="This is not a valid page."
      />
  </Layout>
);

export default NotFoundPage;